@tailwind base;
@tailwind components;
@tailwind utilities;

/* removing default label on phone input  */
.phone-input-custom .special-label {
  display: none;
}

/*custom styles for audio player */
.rhap_play-pause-button {
  display: none;
}
.rhap_container {
  width: 100px;
}

.rhap_controls-section {
  display: none;
  width: 0px;
}
.rhap_container {
  box-shadow: none !important;
  width: auto;
  box-sizing: initial;
  background-color: inherit !important;
  padding: 0px 0px !important;
}

.rhap_progress-bar-show-download {
  height: 3px !important;
  background-color: rgb(212 212 212);
}
.rhap_progress-filled {
  background-color: rgb(84 104 255) !important;
  height: 3px !important;
}
.rhap_progress-indicator {
  background-color: black !important;
  height: 0.5rem !important;
  width: 0.5rem !important;
  top: -3px !important;
  margin-left: -1px !important;
}
.rhap_time {
  font-family: "HK Grotesk";
  font-weight: 500 !important;
  color: black !important;
  font-size: 10px !important;
  letter-spacing: 0.31px !important;
  padding: 1.5px 0.5px !important;
}

/* custom input range slider */
input[type="range"]::-webkit-slider-thumb {
  height: 10px;
  width: 10px;
  border-radius: 9999px;
  background: #f1eeee;
  -webkit-appearance: none;
  margin-top: -4.5px;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 1px;
  height: 1px;
  cursor: pointer;
  animation-duration: 0.2s;
  background: #fff;
  border-width: 2px;
  border-radius: 1.3px;
}

html {
  overflow: auto !important;
}
